import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en.json";
import translationKR from "./kr.json";

const resources: Resource = {
  "en-US": {
    ...translationEN,
  },
  "ko-KR": {
    ...translationKR,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: "ko-KR",
  fallbackLng: {
    "en-US": ["en-US"],
    default: ["ko-KR"],
  },
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
export { resources };
