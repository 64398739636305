import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <hr className="divider" />
      <div className="footer-content">
        <p>© 2024 Franklin Investment. All right reserved.</p>
        <p>projectfranklin23@gmail.com</p>
      </div>
    </footer>
  );
}
