import { ReactComponent as Title } from "../assets/title.svg";
import { useTranslation } from "react-i18next";

import "./Manifesto.css";
import { useEffect, useState } from "react";

export function Manifesto() {
  const { t, i18n } = useTranslation();
  const [englishClassName, setEnglishClassName] = useState("manifesto-english");

  useEffect(() => {
    if (i18n.language === "en-US") {
      setEnglishClassName("manifesto-english");
    } else {
      setEnglishClassName("");
    }
  }, [i18n.language]);

  return (
    <div className={"manifesto " + englishClassName}>
      <Title height="52px" className="manifesto-title" />
      <p>{t("manifesto:1")}</p>
      <p>
        <b>{t("manifesto:2-highlight")}</b> {t("manifesto:2")}
      </p>
      <p>{t("manifesto:3")}</p>
      <p>
        {t("manifesto:4")} <em>{t("manifesto:4-em")}</em>
      </p>
      <p>{t("manifesto:5")}</p>
      <p>{t("manifesto:6")}</p>
      <p>{t("manifesto:7")}</p>
      <p>{t("manifesto:8")}</p>
      <p>{t("manifesto:9")}</p>
      <div className="manifesto-signature">
        <p>{t("manifesto:date")}</p>
        <p>{t("manifesto:writter")}</p>
      </div>
    </div>
  );
}
