import { useTranslation } from "react-i18next";
import "./LanguageButton.css";

export default function LanguageButton() {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === "en-US" ? "ko-KR" : "en-US");
  };

  return (
    <div className="language-button">
      <button onClick={changeLanguage}>
        {i18n.language === "en-US" ? "KR" : "EN"}
      </button>
    </div>
  );
}
